/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var App = {
    	init: function(){
    		this.scrollAnim = null;
    	},
    	parseQueryString: function( queryString ) {
			var params = {}, queries, temp, i, l;
			queryString = queryString.slice(1);
			queries = queryString.split("&");

			for ( i = 0, l = queries.length; i < l; i++ ) {
				temp = queries[i].split('=');
				params[temp[0]] = temp[1];
			}

			return params;
		},
    	animeScroll: function(hash, offset, focusAfter){
	        var el = $(hash);
	        if(offset == undefined){
	            offset = 0;
	        }
            if(focusAfter == undefined){
                focusAfter = null;
            }
	        if(el.length>0){
	            var scroll_offset = el.offset().top - offset;
	            
	            // calculate duration against distance 
	            // to make speed always the same
	            var diff = Math.abs($(window).scrollTop() - scroll_offset);
	            var timeSpeed = diff * 1.5;
	            this.scrollAnim = anime({
	                targets: 'html,body',
	                scrollTop: scroll_offset,
	                duration: timeSpeed,
	                easing: [.14,1,.66,1],
	                complete: function(){
                        var loc = hash.replace('#', '#target=') + "&offset=" + offset;
                        if(focusAfter){
                            if($(focusAfter).length > 0){

                                // Check if select2 instance
                                if($(focusAfter).hasClass("select2-hidden-accessible")){
                                    $(focusAfter).select2('open');
                                }else{
                                    $(focusAfter).focus();
                                }
                                loc += "&focusAfter=" + focusAfter;
                            }
                        }
	                    // if(!offset){
	                        // Add the hash after the animation to make
	                        // :target pseudo selectors work if needed
	                        // only if there is no offset, otherwise jumps to location
	                        // window.location.hash = hash;
	                    // }else{
	                    	window.location.hash = loc;
	                    // }
	                    el.addClass('targeted-once');
	                }
	            });

	            return true;
	        }

	        return false;
	    },
        // All pages
        'common': {
            init: function() {
                // Object fit images, object-fit for images polyfill
                // https://github.com/bfred-it/object-fit-images
                objectFitImages();

                $('.js-target-toggle').targetToggle();
                
            	// Set all dasharrays : anime.setDashoffset doesn't
            	// work because it uses direct attributes values and
            	// we can't use them because our shapes are variable
            	// because of responsive. A general default value has been
            	// set approximately in CSS in case JS isn't working
            	$('.outline rect').each(function(index, el){
            		var rect = el.getBoundingClientRect();
            		var outline = rect.width * 2 + rect.height * 2;
            		$(el).attr('stroke-dasharray', outline);
            		$(el).attr('stroke-dashoffset', outline);
            	});

            	// var navTimeline = anime.timeline({
             //        duration: 5000,
             //        autoplay: false
             //    }).add({
             //        targets: '.nav-in',
             //        height: ['5rem', '3.8rem'],
             //        easing: 'linear',
             //        offset: 0
             //    });

            	// var navTimeline = anime.timeline({
             //        duration: 5000,
             //        autoplay: false
             //    }).add({
             //        targets: '.nav-logo .icn-signature',
             //        opacity: [1, 0],
             //        translateY: ['0%', '-50%'],
             //        easing: 'linear',
             //        offset: 0
             //    }).add({
             //        targets: '.nav-list',
             //        width: ['66.6666667%', '76.6666667%'],
             //        easing: 'linear',
             //        offset: 0
             //    }).add({
             //        targets: '.nav-logo',
             //        width: ['25%', '15%'],
             //        easing: 'linear',
             //        offset: 0
             //    }).add({
             //        targets: '.nav-logo .icn-symbole',
             //        opacity: [0, 1],
             //        translateY: ['50%', '0%'],
             //        easing: 'linear',
             //        offset: 0
             //    }).add({
             //        targets: '.nav-in',
             //        height: ['5rem', '3.8rem'],
             //        easing: 'linear',
             //        offset: 0
             //    });

            	// Nav animation
            	$('.nav-in').each(function(index, el){
                    new visibleProgress($(el), true, 300);
                }).on('progress.vp', function(ev, instance){
                    // navTimeline.seek(navTimeline.duration * instance.rawProgress);

                    if(instance.rawProgress >= 1){
                        $('.nav').addClass('nav-scrolled');
                    }else{
                        $('.nav').removeClass('nav-scrolled');
                    }
                });

                // Header parallax
                var homeHeaderAnim = anime.timeline({
                    duration: 5000,
                    autoplay: false
                }).add({
                    targets: '.hd-slider-wrapper, .hd-banner',
                    translateX: ['-50%', '-50%'],
                    translateY: [0, -310],
                    translateZ: [0, 0],
                    easing: 'linear',
                    offset: 0,
                    // round: 1
                });
                $('.hd-slider-wrapper, .hd-banner').each(function(index, el){
                    new visibleProgress($(el), true, $(window).outerHeight());
                }).on('progress.vp', function(ev, instance){
                    homeHeaderAnim.seek(homeHeaderAnim.duration * instance.rawProgress);
                });

            	// Liens animation scroll
                $('a.js-scrollto, .js-scrollto-wrap a').on('click touchend', document.body, function(ev){
                    var hash = $(this).attr('href');
                    var offset = 0;
                    var focusAfter = null;
                    if($(this).data('offset')){
                        offset = $(this).data('offset');
                    }

                    if($(this).data('focus-after')){
                        focusAfter = $(this).data('focus-after');
                    }

                    if(App.animeScroll(hash, offset, focusAfter)){
                        ev.preventDefault();
                    }
                });
                // stop the animation if we try to scroll
                $(window).on('mousewheel wheel touchstart', function(ev){
                    if(App.scrollAnim != null){
                        App.scrollAnim.pause();
                    }
                });

                // Select2 and search form
                var search_submit = $('#search-submit');
                var select_periode_de_construction = $('.js-select-periode-de-construction').each(function(index, el){
                    var dark = $(el).hasClass('js-select-dark');
                    $(el).select2({
                        formatSelection: function(val) {
                          return val.id;
                        },
                        theme: dark ? 'cdb-dark' : 'cdb',
                        width: '100%'
                    });
                });
                var select_type_architectural = $('.js-select-type-architectural-2015').each(function(index, el){
                    var dark = $(el).hasClass('js-select-dark');
                    $(el).select2({
                        formatSelection: function(val) {
                          return val.id;
                        },
                        theme: dark ? 'cdb-dark' : 'cdb',
                        width: '100%'
                    });
                });
                var select_categorie_de_batiment = $('.js-select-categorie-de-batiment').each(function(index, el){
                    var dark = $(el).hasClass('js-select-dark');
                    $(el).select2({
                        formatSelection: function(val) {
                          return val.id;
                        },
                        theme: dark ? 'cdb-dark' : 'cdb',
                        width: '100%'
                    });
                });
                var select_municipalites = $('.js-select-municipalite').each(function(index, el){
                    var dark = $(el).hasClass('js-select-dark');
                    $(el).select2({
                        formatSelection: function(val) {
                          return val.id;
                        },
                        theme: dark ? 'cdb-dark' : 'cdb',
                        width: '100%'
                    });
                });
                var select_rues = $('.js-select-rue').each(function(index, el){
                    var dark = $(el).hasClass('js-select-dark');
                    $(el).select2({
                        formatSelection: function(val) {
                          return val.id;
                        },
                        theme: dark ? 'cdb-dark' : 'cdb',
                        width: '100%'
                    });
                });
                var select_no_civiques = $('.js-select-no-civique').each(function(index, el){
                    var dark = $(el).hasClass('js-select-dark');
                    $(el).select2({
                        formatSelection: function(val) {
                          return val.id;
                        },
                        theme: dark ? 'cdb-dark' : 'cdb',
                        width: '100%',
                    });
                });

                select_municipalites.on('change', function(ev){
                	select_rues.addClass('cdb-loading').prop('disabled', true).trigger('change');
                	select_no_civiques.prop('disabled', true).trigger('change');
                	$.ajax({
						url: window.ajaxURL,
    					dataType: 'json',
    					method: 'get',
    					data: {
    						'municipalite': $(this).val(),
    						'action': 'get_rues'
    					},
    					success: function(data){
    						select_rues.find("option[value!='-1']").remove();
                            var preloadvalue = select_rues.data('preloadvalue');
    						$.each(data, function(index, el){
    							select_rues.append(new Option(el.text, el.id, false, el.id == preloadvalue));
    						});
    						select_rues.prop('disabled', false).trigger('change').select2('open');
    						select_rues.removeClass('cdb-loading');
                            select_rues.removeAttr('preloadvalue');
    					},
    					error: function(){
    						select_rues.val('-1').prop('disabled', true).trigger('change');
    						select_no_civiques.val('-1').prop('disabled', true).trigger('change');
    					}
					});
                });

                select_rues.on('change', function(ev){
                	select_no_civiques.addClass('cdb-loading').prop('disabled', true).trigger('change');
                	$.ajax({
						url: window.ajaxURL,
    					dataType: 'json',
    					method: 'get',
    					data: {
    						'rue': $(this).val(),
    						'action': 'get_no_civiques',
    						'municipalite': select_municipalites.val()
    					},
    					success: function(data){
    						select_no_civiques.find("option[value!='-1']").remove();
                            var preloadvalue = select_no_civiques.data('preloadvalue');
    						$.each(data, function(index, el){
    							select_no_civiques.append(new Option(el.text, el.id, false, el.id == preloadvalue));
    						});
    						select_no_civiques.prop('disabled', false).trigger('change').select2('open');
    						select_no_civiques.removeClass('cdb-loading');
                            select_no_civiques.removeAttr('preloadvalue');
    					},
    					error: function(){
    						select_no_civiques.val('-1').prop('disabled', true).trigger('change');
    					}
					});
                });

                select_no_civiques.on('change', function(){
                    if(select_no_civiques.val()!='-1'){
                        window.setTimeout(function(){
                            search_submit.addClass('view');
                            search_submit.focus();
                        }, 500);
                    }else{
                        search_submit.removeClass('view');
                    }
                });

                $('#search-bar, #search-form').on('submit', function(ev){
                	if(select_no_civiques.val()!='-1'){
                		window.location.href = select_no_civiques.val();
                		ev.preventDefault();
                	}
                });

                if(select_municipalites.val() != '-1'){
                    select_municipalites.trigger('change');
                }
                if(select_rues.val() != '-1'){
                    select_municipalites.trigger('change');
                }
            },
            finalize: function() {

            },
            load: function(){
                // Initialise tax slider if present
                var tax_slider = $('.block-tax-slider');
                if(tax_slider.length > 0){
                    // tax_slider.each(function(index, el){
                        new Swiper('.block-tax-slider', {
                            direction: 'horizontal',
                            loop: true,
                            slidesPerView: 4,
                            // loopedSlides: 4,
                            spaceBetween: 46,
                            speed: 750,
                            autoplay: {
                                delay: 7000,
                                disableOnInteraction: false
                            },
                            navigation: {
                                nextEl: '.tax-slider-button-next',
                                prevEl: '.tax-slider-button-prev',
                            },
                            breakpoints: {
                                768: {
                                    slidesPerView: 1,
                                    // loopedSlides: 1
                                },
                                1280: {
                                    slidesPerView: 2,
                                    // loopedSlides: 2
                                },
                                1440: {
                                    slidesPerView: 3,
                                    // loopedSlides: 3
                                }
                            }
                        });
                    // })
                }

                $(document.documentElement).addClass('loaded');

                var hash = window.location.hash;
                if(hash != "" && hash.indexOf('#target=') != -1){
                	var params = App.parseQueryString(hash);
                	if(typeof params.target != 'undefined'){
                		params.offset = params.offset || 0;
                        params.focusAfter = params.focusAfter || null;
                		App.animeScroll('#' + params.target, params.offset, params.focusAfter);
                	}

                }
            }
        },
        // Home page
        'home': {
            init: function() {
            	
            },
            finalize: function() {

            },
            load: function(){
            	var homeSlider = new Swiper('.hd-slider', {
            		direction: 'horizontal',
            		loop: false,
            		effect: 'fade',
            		speed: 1500,
            		autoplay: {
            			delay: 7000,
            			disableOnInteraction: false
            		},
            		fadeEffect: {
						crossFade: true
					},
            		pagination: {
            			el: '.swiper-pagination',
            			clickable: true,
	            		renderBullet: function(index, className){
	            			return '<span class="' + className + '"><svg class="outline"><rect x="0" y="0" rx="4" stroke-location="outside" stroke="#fff" stroke-width="2" width="100%" height="100%" fill="none" /></svg></span>';
	            		}
            		}
            	});
            }
        },
        'single_propriete': {
            init: function() {
                if($('.gal').length > 0){
                    $('.gal').Chocolat({
                        imageSize: 'contain',
                        loop: true
                    });
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = App;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        },
        windowLoadEvents: function(){
			// Fire common init JS
            UTIL.fire('common', 'load');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm, 'load');
            });
        }
    };

    App.init();
    // Load Events
    $(document).ready(UTIL.loadEvents);
    $(window).load(UTIL.windowLoadEvents);

})(jQuery); // Fully reference jQuery after this point.
